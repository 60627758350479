import { toString, toNumber } from 'lodash';
import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const GET_PHARMACY_CONFIGURATION = '@PHARMACY/GET_PHARMACY_CONFIGURATION';
export const GET_PHARMACY_CONFIGURATION_SUCCESS = '@PHARMACY/GET_PHARMACY_CONFIGURATION_SUCCESS';
export const GET_PHARMACY_CONFIGURATION_FAIL = '@PHARMACY/GET_PHARMACY_CONFIGURATION_FAIL';

export const UPDATE_PHARMACY_CONFIGURATION = '@PHARMACY/UPDATE_PHARMACY_CONFIGURATION';
export const UPDATE_PHARMACY_CONFIGURATION_SUCCESS = '@PHARMACY/UPDATE_PHARMACY_CONFIGURATION_SUCCESS';
export const UPDATE_PHARMACY_CONFIGURATION_FAIL = '@PHARMACY/UPDATE_PHARMACY_CONFIGURATION_FAIL';

export const CHANGE_PHARMACY_CONFIGURATION_FORM = '@PHARMACY/CHANGE_PHARMACY_CONFIGURATION_FORM';

export const CLEAR_PHARMACY_CONFIGURATION_FORM = '@PHARMACY/CLEAR_PHARMACY_CONFIGURATION_FORM';

export function getPharmacyConfiguration(pharmacyId) {
  return (dispatch) => {
    dispatch({ type: GET_PHARMACY_CONFIGURATION });
    HttpService.get(`/api/pharmacies/${pharmacyId}/configuration`)
      .then((resp) => {
        dispatch({
          type: GET_PHARMACY_CONFIGURATION_SUCCESS,
          payload: { id: pharmacyId, data: parseModelToData(resp.data) },
        });
        dispatch(
          AppActions.updateNavigationItem('qScript', { isInvisible: !resp.data.enableQScript }, 'navigationConfig'),
        );
        dispatch(AppActions.updateNavigationItem('vpb', { isInvisible: !resp.data.enableVPB }, 'navigationConfig'));
        dispatch(
          AppActions.updateNavigationItem(
            'dispenseTracker',
            { isInvisible: !resp.data.enableDispenseTracker },
            'navigationConfig',
          ),
        );
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: GET_PHARMACY_CONFIGURATION_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function updatePharmacyConfiguration() {
  return (dispatch, getState) => {
    const { data, id } = getState().pharmacy.update.configuration;
    dispatch({ type: UPDATE_PHARMACY_CONFIGURATION });
    HttpService.put(`/api/pharmacies/${id}/configuration`, parseDataToModel(data))
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'pharmacy:UPDATE_PHARMACY_CONFIGURATION_SUCCESS',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
        dispatch({ type: UPDATE_PHARMACY_CONFIGURATION_SUCCESS });
        dispatch(
          AppActions.updateNavigationItem(
            'qScript',
            { isInvisible: data.enableQScript !== 'true' },
            'navigationConfig',
          ),
        );
        dispatch(
          AppActions.updateNavigationItem('vpb', { isInvisible: data.enableVPB !== 'true' }, 'navigationConfig'),
        );
        dispatch(
          AppActions.updateNavigationItem(
            'dispenseTracker',
            { isInvisible: data.enableDispenseTracker !== 'true' },
            'navigationConfig',
          ),
        );
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: UPDATE_PHARMACY_CONFIGURATION_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function changePharmacyConfigurationForm(data) {
	return dispatch => {
		dispatch({ type: CHANGE_PHARMACY_CONFIGURATION_FORM, payload: { data } });
	};
}

export function clearUpdateConfigurationForm() {
	return dispatch => {
		dispatch({ type: CLEAR_PHARMACY_CONFIGURATION_FORM });
	};
}

function parseModelToData(model) {
  return {
    dispensingSystem: toString(model.dispensingSystem),
    fredPakStatusActive: toString(model.fredPakStatusActive),
    minfosServerURL: model.minfosServerURL,
    minfosUserName: model.minfosUserName,
    minfosPassword: model.minfosPassword,
    minfosBusinessCode: model.minfosBusinessCode,
    lotsSQLHost: model.lotsSQLHost,
    lotsSQLDatabase: model.lotsSQLDatabase,
    lotsSQLUserName: model.lotsSQLUserName,
    lotsSQLPassword: model.lotsSQLPassword,
    zsqlHost: model.zsqlHost,
    zsqlDatabase: model.zsqlDatabase,
    zsqlUserName: model.zsqlUserName,
    zsqlPassword: model.zsqlPassword,
    corumClearConnectPath: model.corumClearConnectPath,
    corumClearIdentifier: model.corumClearIdentifier,
    fredDataBaseFolder: model.fredDataBaseFolder,
    pathForDORPTS: model.pathForDORPTS,
    fredPakPath: model.fredPakPath,
    pharmacyGuid: model.pharmacyGuid,
    syncCollection: model.syncCollection,
    maxDaysToCheckScript: model.maxDaysToCheckScript || 0,
    alpacaFileExportFolder: model.alpacaFileExportFolder || '',
    packJobPrinting: model.packJobPrinting || false,
    packJobPacking: model.packJobPacking || false,
    packJobChecking: model.packJobChecking || false,
    packJobDistribution: model.packJobDistribution || false,
    zapiServiceURL: model.zapiServiceURL,
    zapiVendorAccessKey: model.zapiVendorAccessKey,
    zapiStoreAccessKey: model.zapiStoreAccessKey,
    zapiUserName: model.zapiUserName,
    zapiBatchId: model.zapiBatchId,
    fredNXTAPIURL: model.fredNXTAPIURL,
    fredNXTAccessKey: model.fredNXTAccessKey,
    fredNXTSubscriptionKey: model.fredNXTSubscriptionKey,
    enableQScript: toString(model.enableQScript),
    enableVPB: toString(model.enableVPB),
    enableDispenseTracker: toString(model.enableDispenseTracker),
    isDoseAdd: toString(model.isDoseAdd),
    isIntegrator: toString(model.isIntegrator),
    integratorGuids: model.integratorGuids,
    dwDatabase: model.dwDatabase,
    dwHost: model.dwHost,
    dwTrustedConnection: model.dwTrustedConnection,
    dwUserName: model.dwUserName,
    dwPassword: model.dwPassword,
    dwqScriptExportFolder: model.dwqScriptExportFolder,
    daysImportNewDrugsDispensed: model.daysImportNewDrugsDispensed,
    fredDispensePlusApiKey: model.fredDispensePlusApiKey,
    fredDispensePlusURL: model.fredDispensePlusURL,
    fredDispensePlusSubscriptionKey: model.fredDispensePlusSubscriptionKey,
    logoutInactivityTime: model.logoutInactivityTime,
    sqsAlpacaQueueName: model.sqsAlpacaQueueName,
  };
}

function parseDataToModel(data) {
  return {
    dispensingSystem: toNumber(data.dispensingSystem),
    fredPakStatusActive: data.fredPakStatusActive === 'true',
    minfosServerURL: data.minfosServerURL,
    minfosUserName: data.minfosUserName,
    minfosPassword: data.minfosPassword,
    minfosBusinessCode: data.minfosBusinessCode,
    lotsSQLHost: data.lotsSQLHost,
    lotsSQLDatabase: data.lotsSQLDatabase,
    lotsSQLUserName: data.lotsSQLUserName,
    lotsSQLPassword: data.lotsSQLPassword,
    zsqlHost: data.zsqlHost,
    zsqlDatabase: data.zsqlDatabase,
    zsqlUserName: data.zsqlUserName,
    zsqlPassword: data.zsqlPassword,
    corumClearConnectPath: data.corumClearConnectPath,
    corumClearIdentifier: data.corumClearIdentifier,
    fredDataBaseFolder: data.fredDataBaseFolder,
    pathForDORPTS: data.pathForDORPTS,
    fredPakPath: data.fredPakPath,
    pharmacyGuid: data.pharmacyGuid,
    syncCollection: data.syncCollection,
    maxDaysToCheckScript: data.maxDaysToCheckScript || 0,
    alpacaFileExportFolder: data.alpacaFileExportFolder || '',
    packJobPrinting: data.packJobPrinting || false,
    packJobPacking: data.packJobPacking || false,
    packJobChecking: data.packJobChecking || false,
    packJobDistribution: data.packJobDistribution || false,
    zapiServiceURL: data.zapiServiceURL,
    zapiVendorAccessKey: data.zapiVendorAccessKey,
    zapiStoreAccessKey: data.zapiStoreAccessKey,
    zapiUserName: data.zapiUserName,
    zapiBatchId: data.zapiBatchId,
    fredNXTAPIURL: data.fredNXTAPIURL,
    fredNXTAccessKey: data.fredNXTAccessKey,
    fredNXTSubscriptionKey: data.fredNXTSubscriptionKey,
    enableQScript: data.enableQScript === 'true',
    enableVPB: data.enableVPB === 'true',
    enableDispenseTracker: data.enableDispenseTracker === 'true',
    isDoseAdd: data.isDoseAdd === 'true',
    isIntegrator: data.isIntegrator === 'true',
    integratorGuids: data.integratorGuids,
    dwDatabase: data.dwDatabase,
    dwHost: data.dwHost,
    dwTrustedConnection: data.dwTrustedConnection || false,
    dwUserName: data.dwUserName,
    dwPassword: data.dwPassword,
    dwqScriptExportFolder: data.dwqScriptExportFolder,
    daysImportNewDrugsDispensed: data.daysImportNewDrugsDispensed,
    fredDispensePlusApiKey: data.fredDispensePlusApiKey,
    fredDispensePlusURL: data.fredDispensePlusURL,
    fredDispensePlusSubscriptionKey: data.fredDispensePlusSubscriptionKey,
    logoutInactivityTime: data.logoutInactivityTime,
    sqsAlpacaQueueName: data.sqsAlpacaQueueName,
  };
}
